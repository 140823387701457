import { USERINFO } from '../constants'

export default function setUserInfo (userInfo = {}, action: any) { // 第一个参数是初始化仓库状态的初始值，第二个参数是action对象
  const { type, data } = action
  switch (type) {
    case USERINFO:
      return data
    default:
      return userInfo
  }
}
