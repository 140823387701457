import { RouterView } from './router'

import './App.scss'

export default () => {
  return (
    <div className="page-container">
      <RouterView/>
    </div>
  )
}
