import React, { Suspense } from 'react'
import {
  useRoutes,
  // useNavigate,
  // useLocation,
  Outlet
} from 'react-router-dom'
import {
  Loading
} from 'antd-mobile'

import routes from './routes'

// 将routes进行路由拼装
const generateRouter = (routers: any) => {
  return routers.map((item: any) => {
    if (item.children) {
      item.children = generateRouter(item.children)
    }
    item.element = <Suspense fallback={
      <div className='loading-container' style={{ fontSize: 24 }}>
        <Loading color='#FEBC5C' />
        <div className='loading-txt'>金东出行</div>
      </div>
    }>
      {/* 把懒加载的异步路由变成组件装载进去 */}
      {
        item.component ? <item.component/> : <><Outlet/></>
      }
    </Suspense>
    return item
  })
}

const Router = () => useRoutes(generateRouter(routes))

// 路由守卫
// const routerBeforeEach = () => {
//   // const navigate = useNavigate()
//   const { pathname } = useLocation()
//   const token = localStorage.getItem('token')
//   useEffect(() => {
//     if (!token) { // 没有token：跳转登录
//       navigate('/login', {
//         replace: true
//       })
//     }
//   }, [pathname])
//   return Boolean(token)
// }

const RouterView = () => {
  // const next: boolean = routerBeforeEach()
  // console.log(next)
  // return next ? <Outlet/> : null
  return <Outlet/>
}

export {
  Router,
  RouterView
}

