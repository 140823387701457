import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import 'reset-css'
import 'lib-flexible'

import { Router } from './router'
import { store, persistor } from './store'

import './styles/common.scss'
console.log(location.href)

const container: any = document.getElementById('root')
const root = ReactDOM.createRoot(container)
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Router/>
      </BrowserRouter>
    </PersistGate>
  </Provider>
)

