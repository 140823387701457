// 用于创建store
import { applyMiddleware, legacy_createStore as createStore } from 'redux'
import thunk from 'redux-thunk' // 引入异步模块
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist' // 数据持久化
import storage from 'redux-persist/lib/storage'
import reducers from './reducers'// 将多个reducer合并为一个

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userInfo'] // 需要缓存的白名单，只有配置了才会进行缓存到
}

const myPersistReducer = persistReducer(persistConfig, reducers)
const store = createStore(myPersistReducer, composeWithDevTools(applyMiddleware(thunk))) // 创建store对象
const persistor = persistStore(store)

export {
  store,
  persistor
}
