import { lazy } from 'react'
import App from '../App'

// routes interface 定义
interface IRoute {
  path: string
  component?: React.FC
  name?: string
  auth?: boolean
  children?: IRoute[]
}

const routes: IRoute[] = [
  {
    path: '/',
    component: App,
    name: 'app',
    children: [
      {
        path: '/',
        name: 'Home',
        component: lazy(() => import(/* webpackChunkName: "home" */'../views/Home'))
      },
      {
        path: '/driver-recruit',
        name: 'driverRecruit',
        component: lazy(() => import(/* webpackChunkName: "driverRecruit" */'../views/DriverRecruit'))
      },
      {
        path: '/driver-app-upload',
        name: 'driverAppUpload',
        component: lazy(() => import(/* webpackChunkName: "driverAppUpload" */'../views/DriverAppUpload'))
      },
      {
        path: '/price',
        name: 'price',
        component: lazy(() => import(/* webpackChunkName: "price" */'../views/Price'))
      },
      {
        path: '/protocol',
        name: 'protocol',
        component: lazy(() => import(/* webpackChunkName: "protocol" */'../views/Protocol/main')),
        children: [
          {
            path: '',
            name: 'protocolIndex',
            component: lazy(() => import(/* webpackChunkName: "protocolIndex" */'../views/Protocol/index'))
          },
          {
            path: 'service',
            name: 'protocolService',
            component: lazy(() => import(/* webpackChunkName: "protocolService" */'../views/Protocol/service'))
          },
          {
            path: 'privacy',
            name: 'protocolPrivacy',
            component: lazy(() => import(/* webpackChunkName: "protocolPrivacy" */'../views/Protocol/privacy'))
          },
          {
            path: 'software',
            name: 'protocolSoftware',
            component: lazy(() => import(/* webpackChunkName: "protocolSoftware" */'../views/Protocol/software'))
          },
          {
            path: 'platform',
            name: 'protocolPlatform',
            component: lazy(() => import(/* webpackChunkName: "protocolPlatform" */'../views/Protocol/platform'))
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: lazy(() => import(/* webpackChunkName: "login" */'../views/Login'))
  },
  {
    path: '*',
    name: 'notFound',
    component: lazy(() => import(/* webpackChunkName: "notFound" */'../views/NotFound'))
  }
]

export default routes
